import {
  BannerDTO,
  CourseDTO,
  CourseRecordDTO,
  GymDTO,
  MembershipDTO,
  UserMemberShipDTO,
  UserRecordDTO,
  enumType,
  z
} from "./chunk-2GEZ6RMO.js";

// src/rest/gym.ts
var GymZod = GymDTO.omit({
  createdAt: true
}).extend({
  coordinates: z.string().refine(
    (value) => {
      const coordinatesPattern = /^-?\d+(\.\d+)?,-?\d+(\.\d+)?$/;
      return coordinatesPattern.test(value);
    },
    {
      message: "\u5750\u6807\u5FC5\u987B\u662F\u4E24\u4E2A\u7531\u9017\u53F7\u5206\u9694\u7684\u6D6E\u70B9\u6570"
    }
  ),
  phoneNum: z.string().refine(
    (value) => {
      const phonePattern = /^1[3456789]\d{9}$/;
      return phonePattern.test(value);
    },
    {
      message: "\u7535\u8BDD\u53F7\u7801\u5FC5\u987B\u662F\u4E2D\u56FD\u7684\u624B\u673A\u53F7\u7801"
    }
  )
});
var CoachZod = z.object({
  id: z.string(),
  name: z.string(),
  avatar: z.string(),
  phoneNum: z.string(),
  intro: z.string(),
  coachCourses: z.array(
    z.object({
      id: z.string(),
      name: z.string()
    })
  )
});
var CoachUpdateZod = z.object({
  intro: z.string().min(1).max(150),
  coursesId: z.array(z.string())
});

// src/rest/types.ts
import { extendZodWithOpenApi } from "@asteasolutions/zod-to-openapi";
extendZodWithOpenApi(z);

// src/rest/user.ts
var userResp = z.object({
  id: z.number(),
  name: z.string().optional(),
  email: z.string().optional(),
  intro: z.string().optional(),
  avatar: z.string().optional()
});
var getUsersResp = z.object({ users: z.array(userResp) });
var getUser = {
  path: "/user",
  get: {
    responseZod: userResp
  }
};
var userWithGymResp = userResp.extend({
  ownedGyms: z.array(GymZod)
});
var getUsers = {
  path: "/users",
  get: {
    responseZod: getUsersResp
  }
};
var getUserById = {
  path: "/users/{id}",
  get: {
    responseZod: userResp,
    urlParam: {
      params: z.object({
        id: z.string().min(1)
      }),
      query: z.object({
        gymId: z.string().min(1)
      })
    }
  }
};
var updateUserReq = z.object({
  name: z.string().min(1).optional(),
  email: z.string().email().optional(),
  intro: z.string().optional(),
  avatar: z.string().optional(),
  phoneNum: z.string().optional()
});
var updateUser = {
  path: "/users/{id}",
  put: {
    requestZod: updateUserReq,
    responseZod: userResp,
    urlParam: {
      params: z.object({
        id: z.string().min(1)
      })
    }
  }
};
var deleteUser = {
  path: "/users/{id}",
  delete: {
    urlParam: {
      params: z.object({
        id: z.string().min(1)
      })
    }
  }
};

// src/rest/auth.ts
var authorResponseZod = z.object({
  token: z.string(),
  id: z.string().optional(),
  name: z.string().optional(),
  avatar: z.string().optional(),
  role: z.string().optional()
});
var loginReqZod = z.object({
  phoneNum: z.string().min(11).max(11),
  password: z.string().min(6).max(20)
});
var loginWithPhoneNum = {
  path: "/login",
  post: {
    requestZod: loginReqZod,
    responseZod: authorResponseZod
  }
};
var registerReqZod = z.object({
  phoneNum: z.string().min(11).max(11),
  password: z.string().min(6).max(15)
});
var register = {
  path: "/register",
  post: {
    requestZod: z.object({
      phoneNum: z.string().min(11).max(11),
      password: z.string().min(6).max(20)
    }),
    responseZod: authorResponseZod
  }
};
var wechatOAuthReqZod = z.object({
  code: z.string()
});
var wechatOAuth = {
  path: "/auth",
  post: {
    requestZod: wechatOAuthReqZod,
    responseZod: authorResponseZod
  }
};

// src/rest/fileUpload.ts
var imageREST = {
  path: "/image",
  post: {
    requestZod: z.object({
      width: z.string().optional(),
      height: z.string().optional(),
      image: z.any()
    }),
    responseZod: z.object({
      src: z.string()
    })
  }
};

// src/rest/banner.ts
var BannerZod = BannerDTO.omit({
  createdAt: true
});

// src/rest/course.ts
var CourseZod = CourseDTO.omit({
  createdAt: true
});
var CourseType = ["group", "private"];
var CourseTypeZod = enumType(CourseType);
var PayType = /* @__PURE__ */ ((PayType2) => {
  PayType2[PayType2["Membership"] = 1] = "Membership";
  PayType2[PayType2["Free"] = 2] = "Free";
  return PayType2;
})(PayType || {});
var DefaultPayType = 0;
var DefaultPayTypeStr = "\u9ED8\u8BA4(\u4F1A\u5458\u5361)";
var CoursePayTypeNameDic = {
  [2 /* Free */]: "\u514D\u8D39",
  [1 /* Membership */]: "\u4F1A\u5458\u5361"
};
var CourseTypeNameDic = {
  group: "\u56E2\u8BFE",
  private: "\u79C1\u6559"
};

// src/rest/membership.ts
var MembershipZod = MembershipDTO.omit({
  createdAt: true,
  updatedAt: true
});
var MembershipCardThemeZod = z.enum(["orange", "dark", "gold"]);
var MembershipCardThemeNameDic = {
  orange: "\u6A59",
  dark: "\u9ED1",
  gold: "\u91D1"
};
var MembershipCardThemeColor = {
  orange: "#ff8c00",
  dark: "#000",
  gold: "#ffd700"
};
var MembershipWithCoursesZod = MembershipZod.merge(
  z.object({
    theme: MembershipCardThemeZod,
    courses: z.array(
      CourseZod.pick({
        id: true,
        name: true
      })
    )
  })
);
var MembershipWithCoursesUploadZod = MembershipZod.merge(
  z.object({
    courses: z.array(z.string()).refine((val) => val.length > 0, { message: "\u8BFE\u7A0B\u4E0D\u80FD\u4E3A\u7A7A" })
  })
);
var MembershipType = ["period", "times"];
var MembershipTypeZod = z.enum(MembershipType);
var MembershipTypeNameDic = {
  period: "\u671F\u9650\u5361",
  times: "\u6B21\u5361"
};
var userPeriodMembershipUpdateZod = UserMemberShipDTO.omit({
  createdAt: true,
  updatedAt: true,
  records: true,
  total: true
});
var userMembershipUpdateZod = UserMemberShipDTO.omit({
  createdAt: true,
  updatedAt: true,
  records: true
});
var userTimesMembershipUpdateZod = UserMemberShipDTO.omit({
  createdAt: true,
  updatedAt: true,
  records: true
});
var userMembershipZod = UserMemberShipDTO.omit({
  createdAt: true,
  updatedAt: true
}).merge(
  z.object({
    membership: MembershipZod,
    _count: z.object({
      records: z.number()
    })
  })
);

// src/rest/courseRecord.ts
var courseRecordCreateOrUpdateZod = CourseRecordDTO.omit({
  createdAt: true
}).extend({
  id: z.string().optional()
});
var CourseStatusZod = z.union([
  z.literal("default"),
  z.literal("cancelByAdmin"),
  z.literal("cancelByUser")
]);
var CourseStatus = Object.fromEntries(
  CourseStatusZod.options.map((option) => [option.value, option.value])
);
var CourseStatusDic = {
  default: "",
  cancelByAdmin: "\u7CFB\u7EDF\u53D6\u6D88",
  cancelByUser: "\u5DF2\u53D6\u6D88"
};
var CourseStatusLabel = {
  default: "\u9ED8\u8BA4\uFF08\u6B63\u5E38\u5F00\u8BFE\uFF09",
  cancelByAdmin: "\u7BA1\u7406\u5458\u53D6\u6D88",
  cancelByUser: "\u7528\u6237\u53D6\u6D88"
};
var UserCourseStatus = {
  default: "default",
  checkedIn: "checkedIn",
  cancelByUser: "cancelByUser"
};
var UserCourseStatusDic = {
  default: "\u5DF2\u7EA6",
  checkedIn: "\u5DF2\u7B7E\u5230",
  cancelByUser: "\u5DF2\u53D6\u6D88"
};
var courseRecordZod = CourseRecordDTO.omit({ createdAt: true }).extend(
  {
    userRecords: z.array(
      z.object({
        user: z.object({
          id: z.string(),
          name: z.string(),
          phoneNum: z.string(),
          avatar: z.string().optional()
        }),
        userMembership: z.object({ membership: z.object({ name: z.string() }) }).optional(),
        status: z.nativeEnum(UserCourseStatus),
        createdAt: z.string()
      })
    ),
    _count: z.object({
      userRecords: z.number()
    }),
    course: CourseZod,
    coach: userResp
  }
);

// src/rest/gymWeapp.ts
var WeAppREST;
((WeAppREST2) => {
  WeAppREST2.GymZod = GymDTO.omit({
    id: true,
    ownerId: true,
    createdAt: true
  });
  WeAppREST2.CourseZod = CourseDTO.omit({
    gymId: true,
    createdAt: true
  });
  WeAppREST2.MembershipZod = MembershipDTO.omit({
    showForSale: true,
    gymId: true,
    createdAt: true
  }).extend({
    courses: z.array(WeAppREST2.CourseZod)
  });
  WeAppREST2.CoachZod = CoachZod;
  WeAppREST2.BannerZod = BannerDTO.omit({
    id: true,
    createdAt: true,
    gymId: true
  });
  WeAppREST2.GymResponseZod = WeAppREST2.GymZod.extend({
    memberships: z.array(WeAppREST2.MembershipZod),
    banners: z.array(WeAppREST2.BannerZod),
    coaches: z.array(WeAppREST2.CoachZod),
    courses: z.array(WeAppREST2.CourseZod)
  });
  WeAppREST2.CourseRecordZod = CourseRecordDTO.omit({
    gymId: true,
    createdAt: true
  }).extend({
    _count: z.object({
      userRecords: z.number()
    })
  });
  WeAppREST2.CourseRecordCreateOrUpdateZod = CourseRecordDTO.omit({
    endTime: true,
    createdAt: true
  }).extend({
    id: z.string().optional(),
    status: z.nativeEnum(CourseStatus).default("default")
  });
  WeAppREST2.UserGroupRecordCreateOrUpdateZod = UserRecordDTO.omit({
    createdAt: true
  }).extend({
    id: z.string().optional(),
    status: z.nativeEnum(UserCourseStatus).default("default")
  });
  WeAppREST2.UserPrivateRecordCreateOrUpdateZod = UserRecordDTO.omit({
    createdAt: true,
    courseRecordId: true
  }).extend({
    id: z.string().optional(),
    status: z.nativeEnum(UserCourseStatus).default("default"),
    courseRecord: WeAppREST2.CourseRecordCreateOrUpdateZod.extend({
      startTime: z.string()
    })
  });
  WeAppREST2.UserRecordZod = z.object({
    id: z.string(),
    status: z.nativeEnum(UserCourseStatus),
    payType: z.number().int(),
    membershipId: z.string().nullish(),
    courseRecord: WeAppREST2.CourseRecordZod
  });
})(WeAppREST || (WeAppREST = {}));

// src/rest/index.ts
function convertPathParams(path, params) {
  let newPath = path;
  for (const key in params) {
    if (path.includes(`{${key}}`)) {
      newPath = newPath.replace(`{${key}}`, params[key]);
    } else {
      throw new Error(`Parameter {${key}} not found in path`);
    }
  }
  return newPath;
}

export {
  GymZod,
  CoachZod,
  CoachUpdateZod,
  userResp,
  getUsersResp,
  getUser,
  userWithGymResp,
  getUsers,
  getUserById,
  updateUserReq,
  updateUser,
  deleteUser,
  authorResponseZod,
  loginReqZod,
  loginWithPhoneNum,
  registerReqZod,
  register,
  wechatOAuth,
  imageREST,
  BannerZod,
  CourseZod,
  CourseType,
  CourseTypeZod,
  PayType,
  DefaultPayType,
  DefaultPayTypeStr,
  CoursePayTypeNameDic,
  CourseTypeNameDic,
  MembershipZod,
  MembershipCardThemeZod,
  MembershipCardThemeNameDic,
  MembershipCardThemeColor,
  MembershipWithCoursesZod,
  MembershipWithCoursesUploadZod,
  MembershipType,
  MembershipTypeZod,
  MembershipTypeNameDic,
  userPeriodMembershipUpdateZod,
  userMembershipUpdateZod,
  userTimesMembershipUpdateZod,
  userMembershipZod,
  courseRecordCreateOrUpdateZod,
  CourseStatusZod,
  CourseStatus,
  CourseStatusDic,
  CourseStatusLabel,
  UserCourseStatus,
  UserCourseStatusDic,
  courseRecordZod,
  WeAppREST,
  convertPathParams
};
