import { apiClient } from '~/lib/request'
import { z } from 'zod'
import {
  userWithGymResp,
  getUser,
  GymZod,
  CourseZod,
  MembershipWithCoursesZod,
  CoachZod
} from '@gym-rose/data'
import { getToken, removeToken } from '~/lib/cookie'
import useSWR from 'swr'
import { usePathname, useRouter } from 'next/navigation'
import { useCallback } from 'react'

export const UserZod = userWithGymResp.extend({
  ownedGyms: z.array(
    GymZod.extend({
      courses: z.array(CourseZod),
      memberships: z.array(MembershipWithCoursesZod),
      coaches: z.array(CoachZod)
    })
  )
})

export type User = z.infer<typeof UserZod>
export const useUser = () => {
  const pathname = usePathname()
  const router = useRouter()

  const {
    data: resp,
    isLoading: loading,
    mutate
  } = useSWR(
    getUser.path,
    async () => {
      const token = await getToken()
      if (!token) {
        pathname !== '/' && router.push('/')
        return null
      }
      return apiClient.getUser()
    },
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      shouldRetryOnError: false,
      onError: error => {
        if (error.code === 401 || error.code === 403) {
          signOut()
          return
        }
        return true
      }
    }
  )

  const signOut = useCallback(() => {
    removeToken()
    router.push('/')
    mutate(undefined, true)
  }, [router, mutate])
  return {
    user: resp?.data,
    loading,
    signOut,
    ownedGym: (resp?.data as User)?.ownedGyms[0] ?? null,
    mutate
  }
}
